<template>
	<section class="services" ref="servsection">

		<div class="container">
			<h2 class="header5">Our Core Services</h2>
		</div>

		<div ref="servitem" class="service bganim" v-for="(service, i) in slice.items" :key="`service-${i}`">
			<NuxtLink :to="`/services/${service.link.uid}`" :aria-label="service.link.uid" @mouseenter="soundStore.playHover"
				@click="soundStore.playClick">
				<div class="container">
					<div class="grid vcenter">
						<div class="thirds">
							<span>0{{ i + 1 }}</span>
							<h3 class="service-header">{{ service.service }}</h3>
						</div>
						<div class="third">
							<div class="description">
								<h4 class="header5">{{ service.title }}</h4>
								<prismic-rich-text :field="service.description" />
							</div>
						</div>
					</div>
				</div>
			</NuxtLink>
		</div>

	</section>
</template>

<script setup>

import { gsap } from 'gsap'
import { ScrollTrigger } from 'gsap/ScrollTrigger'
gsap.registerPlugin(ScrollTrigger)

const soundStore = useSoundStore()

const props = defineProps(['slice'])
const servsection = ref()
const servitem = ref()

onMounted(() => {

	if (servitem.value) {
		gsap.from(servitem.value, {
			scrollTrigger: {
				trigger: servsection.value,
				start: 'top 75%',
				end: 'center center',
				scrub: 0.2,
			},
			x: '-=100%',
			duration: 0.5,
			stagger: 0.1
		})
	}

})

</script>


<style lang="scss" scoped>
.services {
	padding: 150px 0px;

	.service {
		a {
			color: var(--color-white);
		}

		.description {
			opacity: 0;
			transition: opacity 0.5s ease-out;

			@media only screen and (max-width: 991px) {
				opacity: 1;
			}

			@media only screen and (max-width: 767px) {
				opacity: 1;
			}
		}

		span {
			font-family: 'AMAR Accent';
			font-size: 24px;
			color: var(--color-primary);
		}

		&:hover {
			.description {
				opacity: 1;
			}
		}
	}
}
</style>

